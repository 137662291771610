// import {ROUTES} from '@/config/constants'
// import {oktaAuth} from '@/helpers/oktaConfig'
import {AuthState} from '@okta/okta-auth-js'
// import {useOktaAuth} from '@okta/okta-react'
import {PropsWithChildren, createContext} from 'react'
// import {useNavigate} from 'react-router-dom'
// import {isSUT} from '../../utils/env'

export const AuthContext = createContext<{isReady: boolean; isAuthenticated: boolean; authState: AuthState | null}>({
  isReady: false,
  isAuthenticated: false,
  authState: null,
})

export const AuthProvider: React.FC<PropsWithChildren> = ({children}) => {
  // const navigate = useNavigate()

  // const restoreOriginalUri = async () => {
  //   navigate(toRelativeUrl(ROUTES.LIST_NBAS, window.location.origin))
  // }

  return (
    // <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
    <Provider>{children}</Provider>
    // </Security>
  )
}

const Provider: React.FC<PropsWithChildren> = ({children}) => {
  // const {authState} = useOktaAuth()

  // const value = useMemo(() => {
  //   return {
  //     isReady: isSUT() || !!authState,
  //     isAuthenticated: isSUT() || !!authState?.isAuthenticated,
  //     authState,
  //   }
  // }, [authState])

  return children
}
